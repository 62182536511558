import toilet from "./Data/Sounds_MP3/toilet1.mp3";
import no from "./Data/Sounds_MP3/no.mp3";
import yes from "./Data/Sounds_MP3/yes.mp3";
import love from "./Data/Sounds_MP3/iloveyou.mp3";
import sleep from "./Data/Sounds_MP3/sleep.mp3";
import eat from "./Data/Sounds_MP3/eat.mp3";
import home from "./Data/Sounds_MP3/home.mp3";
import outside from "./Data/Sounds_MP3/outside.mp3";
import play from "./Data/Sounds_MP3/play.mp3";
import water from "./Data/Sounds_MP3/water.mp3";
import "./styles.css";

export default function App() {
  const audio_toilet = new Audio(toilet);
  audio_toilet.loop = false;

  const audio_no = new Audio(no);
  audio_no.loop = false;

  const audio_yes = new Audio(yes);
  audio_yes.loop = false;

  const audio_love = new Audio(love);
  audio_love.loop = false;

  const audio_sleep = new Audio(sleep);
  audio_sleep.loop = false;

  const audio_eat = new Audio(eat);
  audio_eat.loop = false;

  const audio_home = new Audio(home);
  audio_eat.loop = false;

  const audio_outside = new Audio(outside);
  audio_outside.loop = false;

  const audio_water = new Audio(water);
  audio_eat.loop = false;

  const audio_play = new Audio(play);
  audio_play.loop = false;
  return (
    <div className="App">
      <div>
        <h1 className="app-header">Zaid Talk</h1>
      </div>
      <div className="grid-container">
        <div className="grid-item">
          <button type="button" id="b1" className="small_btn">
            <button>
              <img
                className="no-bitting-img"
                src="../Images/toilet.jpg"
                alt="no bitting"
                onClick={() => {
                  audio_toilet.loop = false;
                  audio_toilet.play();
                }}
              />
            </button>
          </button>
        </div>
        <div className="grid-item">
          <button type="button" id="b2" className="small_btn">
            <button className="talk-btn">
              <img
                className="no-bitting-img"
                src="../Images/yes.jpg"
                alt="no bitting"
                onClick={() => {
                  audio_yes.loop = false;
                  audio_yes.play();
                }}
              />
            </button>
          </button>
        </div>
      </div>
      <div className="grid-container">
        <div className="grid-item">
          <button type="button" id="b1" className="small_btn">
            <button className="talk-btn">
              <img
                className="no-bitting-img"
                src="../Images/no.jpg"
                alt="no bitting"
                onClick={() => {
                  audio_no.loop = false;
                  audio_no.play();
                }}
              />
            </button>
          </button>
        </div>

        <div className="grid-item">
          <button type="button" id="b2" className="small_btn">
            <button className="talk-btn">
              <img
                className="no-bitting-img"
                src="../Images/love.jpeg"
                alt="no bitting"
                onClick={() => {
                  audio_love.loop = false;
                  audio_love.play();
                }}
              />
            </button>
          </button>
        </div>
        <div className="grid-item">
          <button type="button" id="b2" className="small_btn">
            <button className="talk-btn">
              <img
                className="no-bitting-img"
                src="../Images/eat.jpg"
                alt="no bitting"
                onClick={() => {
                  audio_eat.loop = false;
                  audio_eat.play();
                }}
              />
            </button>
          </button>
        </div>
        <div className="grid-item">
          <button type="button" id="b2" className="small_btn">
            <button className="talk-btn">
              <img
                className="no-bitting-img"
                src="../Images/water.png"
                alt="no bitting"
                onClick={() => {
                  audio_water.loop = false;
                  audio_water.play();
                }}
              />
            </button>
          </button>
        </div>
        <div className="grid-item">
          <button type="button" id="b2" className="small_btn">
            <button className="talk-btn">
              <img
                className="no-bitting-img"
                src="../Images/play.png"
                alt="no bitting"
                onClick={() => {
                  audio_play.loop = false;
                  audio_play.play();
                }}
              />
            </button>
          </button>
        </div>
        <div className="grid-item">
          <button type="button" id="b2" className="small_btn">
            <button className="talk-btn">
              <img
                className="no-bitting-img"
                src="../Images/sleep.jpg"
                alt="no bitting"
                onClick={() => {
                  audio_sleep.loop = false;
                  audio_sleep.play();
                }}
              />
            </button>
          </button>
        </div>
        <div className="grid-item">
          <button type="button" id="b2" className="small_btn">
            <button className="talk-btn">
              <img
                className="no-bitting-img"
                src="../Images/home.png"
                alt="no bitting"
                onClick={() => {
                  audio_home.loop = false;
                  audio_home.play();
                }}
              />
            </button>
          </button>
        </div>
        <div className="grid-item">
          <button type="button" id="b2" className="small_btn">
            <button className="talk-btn">
              <img
                className="no-bitting-img"
                src="../Images/outside.png"
                alt="no bitting"
                onClick={() => {
                  audio_outside.loop = false;
                  audio_outside.play();
                }}
              />
            </button>
          </button>
        </div>
      </div>
      <footer>Developed by your father Abdulaziz Al-Attar 2023&copy; </footer>
    </div>
  );
}
